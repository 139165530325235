<template>
  <div class="p-text-center p-mt-5">
    <h1>Confirm Password Reset</h1>
  </div>

  <div class="p-grid p-mt-4 p-jc-center">
    <div class="p-fluid p-col p-md-3 p-lg-4 p-xl-6">
      <div class="card">

        <h4 class="p-mt-1">Recuperación de contraseña</h4>

        <form @submit.prevent="confirmPasswordReset">
          <div class="p-field">
            <label for="email">Email</label>
            <div class="p-text-center">
              <span class="p-input-icon-left">
                <i class="pi pi-user"/>
                <InputText ref="emailInput" id="email" type="email" :class="errors.email ? 'p-invalid' : ''"
                           v-model="form.email" placeholder="Email" required autofocus
                           :disabled="emailReadonly"
                           :readonly="emailReadonly"/>

                <div v-if="errors.email">
                  <small id="email-error" class="p-error">{{ this.errors.email }}</small>
                </div>
              </span>
            </div>
          </div>

          <div class="p-field">
            <label for="password">Nueva contraseña</label>
            <div class="p-text-center">
              <span class="p-input-icon-left">
                <i class="pi pi-key"/>
                <InputText ref="passwordInput" id="password" type="password" :class="errors.password ? 'p-invalid' : ''"
                           v-model="form.password" placeholder="Password" required/>

                <div v-if="errors.password">
                  <small id="password-error" class="p-error">{{ this.errors.password }}</small>
                </div>
              </span>
            </div>
          </div>

          <div class="p-mt-3 p-text-center">
            <Button type="submit" label="Submit" :loading="loading"/>
          </div>
        </form>

        <Toast/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        token: null,
        password: null,
      },
      errors: {
        email: null,
        token: null,
        password: null,
      },
      loading: false,
      emailReadonly: false
    }
  },
  methods: {
    async confirmPasswordReset() {
      this.loading = true;

      try {
        await this.$repository.auth.confirmPasswordReset(this.form)

        this.$toast.add({
          severity: 'success',
          summary: 'Contraseña actualizada',
          detail: 'Seras redireccionado al login en un momento',
          life: 3000
        });

        setTimeout(() => {
          this.$router.push('login');
        }, 3000)

      } catch (err) {
        const res = err.response;
        this.$toast.add({
          severity: 'error',
          summary: res?._error_code ?? 'Error',
          detail: res?.data.message ?? null,
          life: 1000
        });
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
      this.emailReadonly = true;
    }

    if (this.$route.query.token) {
      this.form.token = this.$route.query.token;
    }
  }
}
</script>

<style scoped>

</style>